import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
    _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
    _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
    _createVNode(_component_v_icon, {
      class: "mb-5",
      color: "amber-lighten-3",
      icon: "mdi-close-circle-outline",
      size: "112"
    }),
    _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
    _createVNode(_component_v_card_title, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Что-то пошло не так")
      ])),
      _: 1
    }),
    _createVNode(_component_v_sheet, {
      width: "320px",
      class: "d-inline-flex flex-column justify-start"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_form, null, {
          default: _withCtx(() => [
            (_ctx.$route.query['text'])
              ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$route.query['text']), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_btn, { onClick: _ctx.close }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" OK ")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}