

import SvgMap from "@/components/Map.vue";
import {Component, Vue} from "vue-facing-decorator";

@Component({
  components: {
    SvgMap
  }
})
export default class App extends Vue {

  isInIFrame() {
    return (window.top !== window.self) || this.$route.query["debug"]
  }

  created() {

    if (!process.env.VUE_APP_API_URL)
      console.error('env.VUE_APP_API_URL not defined!')

    this.ymInit()

  }

  ymInit() {
    // Яндекс Метрика

    if (!window.ym) return;

    let ymConfig = {}
    if (document.referrer.includes('vegas-hall.ru')) {
      ymConfig = {
        id: '39017335',
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        ecommerce: "dataLayer",
        //webvisor: true,
        //trackHash: true
      }
    } else {
      for (const key of Object.keys(this.$route.query))
        if (key.includes('ym_'))
          ymConfig[key.replace('ym_', '')] = this.$route.query[key]

    }

    if (Object.keys(ymConfig).length) {
      window.ym(ymConfig['id'], "init", ymConfig);
    }
  }

}

