import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
// Vuetify
import 'vuetify/styles'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {aliases, mdi} from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'
//import * as Sentry from "@sentry/vue";

const vuetify = createVuetify({
    components,
    directives,
    display: {
        mobileBreakpoint: 'sm',
        thresholds: {
            xs: 0,
            sm: 340,
            md: 540,
            lg: 800,
            xl: 1280,
        },
    },
    theme: {
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: '#ea5f5a', //'#d39c23', // #FF6E40
                    secondary: '#90A4AE'
                }
            },
        },
    },
    icons: {
        defaultSet: 'mdi',  // даже при дефолтном сете нужно или установить npm install @mdi/font или прописать CDN
        aliases,
        sets: {
            mdi,
        },
    },
})

const app = createApp(App)
//
// Sentry.init({
//     app,
//     dsn: "https://5a1c837fb090437e97f32f26bdaad757@o4505566600822784.ingest.sentry.io/4505566987485184",
//     integrations: [
//         new Sentry.BrowserTracing({
//             // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//             tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         }),
//         new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

app.use(vuetify)
app.use(router)
app.mount('#app')

