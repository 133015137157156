import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Success from '../views/Success.vue'
import Fail from '../views/Fail.vue'
import QR from '../views/QR.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/success',
    name: 'success',
    component: Success
  },
  {
    path: '/fail',
    name: 'fail',
    component: Fail
  },
  {
    path: '/qr',
    name: 'qr',
    component: QR
  }
]

const router = createRouter({
  //history: createWebHashHistory(), // спользует # в пути , и сбербанк не может перенаправить на такой url клиента
  history: createWebHistory(), // не спользует # в пути
  routes
})

export default router
