import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, null, {
    default: _withCtx(() => [
      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
      _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
      _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
      _createVNode(_component_v_icon, {
        class: "mb-5",
        color: "success",
        icon: "mdi-check-circle",
        size: "112"
      }),
      _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Успешная покупка")
        ])),
        _: 1
      }),
      _createVNode(_component_v_sheet, {
        width: "320px",
        class: "d-inline-flex flex-column justify-start"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Ваши билеты отправлены на почту ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_btn, { onClick: _ctx.close }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" OK ")
                ])),
                _: 1
              }, 8, ["onClick"]),
              _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
              _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}