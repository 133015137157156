import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  type: "image/svg+xml",
  data: "https://sbp.nspk.ru/i/logo-black.svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
    _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
    _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
    _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
    _createVNode(_component_v_sheet, { class: "d-flex flex-column justify-center align-center" }, {
      default: _withCtx(() => [
        (_ctx.isSBP())
          ? (_openBlock(), _createElementBlock("object", _hoisted_1, _cache[0] || (_cache[0] = [
              _createElementVNode("img", { src: "https://sbp.nspk.ru/i/logo-black.svg" }, null, -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.isSBP())
          ? (_openBlock(), _createBlock(_component_v_card_title, { key: 1 }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Сканируйте QR код "),
                _createElementVNode("br", null, null, -1),
                _createTextVNode("в приложении Вашего банка")
              ])),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_v_card_title, { key: 2 }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("QR ссылки на оплату, наведите камеру телефона")
              ])),
              _: 1
            })),
        _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
        (_ctx.$route.query['url'])
          ? (_openBlock(), _createBlock(_component_v_img, {
              key: 3,
              width: "260",
              src: 'https://api.qrserver.com/v1/create-qr-code/?size=260x260&data='+_ctx.$route.query['url']
            }, null, 8, ["src"]))
          : (_openBlock(), _createBlock(_component_v_card_title, {
              key: 4,
              class: "text-red-accent-2"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Нет ссылки!")
              ])),
              _: 1
            })),
        _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
        (!_ctx.isSBP())
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 5,
              disabled: !_ctx.$route.query['url'],
              color: "primary",
              href: _ctx.$route.query['url']
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Перейти... ")
              ])),
              _: 1
            }, 8, ["disabled", "href"]))
          : _createCommentVNode("", true),
        (_ctx.$route.query['orderId'])
          ? (_openBlock(), _createBlock(_component_v_card_title, { key: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("Заказ #" + _toDisplayString(_ctx.$route.query['orderId']), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.$route.query['amount'])
          ? (_openBlock(), _createBlock(_component_v_card_title, { key: 7 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$route.query['amount']) + ",00 руб.", 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
        (_ctx.isSBP() && !!_ctx.$vuetify.display.mobile)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 8,
              disabled: !_ctx.$route.query['url'],
              color: "green",
              onClick: _ctx.openSBPWidget
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Оплатить в приложении банка ")
              ])),
              _: 1
            }, 8, ["disabled", "onClick"]))
          : _createCommentVNode("", true),
        _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
        _cache[11] || (_cache[11] = _createTextVNode()),
        _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
        _createVNode(_component_v_btn, { onClick: _ctx.close }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" Закрыть ")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
        _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1))
      ]),
      _: 1
    })
  ]))
}