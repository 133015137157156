export function redirect(url) {

    return !!window.open(url, '_blank')

    // Работает, но надо буде тувеличить высотку iframe и ссылки вернуться в магазин откроются внутри iframe
    // try {
    //     window.location.href = url
    //     return true
    //
    // } catch (e) {
    //     console.error('Браузер заблокировал редирект URL:', e)
    //     return !!window.open(url, '_blank')
    // }

}


export function closeWidget() {
    // К паренту нельзя обращаться, будет исключение
    //console.log('window.parent.origin', window.parent.origin)

    // Если в iFrame
    if (window.parent)
        window.parent?.postMessage('chTicketWidget.close','*')
    // Если прямая ссылка
    //else


}
