import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_card_title = _resolveComponent("v-card-title")!

  return (true || _ctx.isInIFrame())
    ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
    : (_openBlock(), _createBlock(_component_v_card_title, { key: 1 }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Виджет доступен только в iFrame")
        ])),
        _: 1
      }))
}